<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}">

        <div class="row">
            <validated-input class="col-12" label="Name" v-model="model.name"
                         :rules="rules.name" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-input class="col-6" label="Place" v-model="model.place"
                         :rules="rules.place" :disabled="loading"/>

            <file-input class="c-file-input col-6" label="Profile img" v-model="model.profile_img" :disabled="loading"/>
        </div>
        <div class="row">
            <div class="col">
                <image-info :width="360" :height="360"/>
            </div>
        </div>

        <div class="row">
            <validated-input class="col-12" label="Comment" v-model="model.comment" size="150" type="text"
                         :rules="rules.comment" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddTestimonials',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.event.testimonials.addEdit,
            rules  : {
                name : {
                    required : true
                },
                place : {
                    required : true
                },
                profile_img : {
                    required : true
                },
                comment : {
                    max      : 210,
                    min      : 200,
                    required : true
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Testimonial..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
